import React, { Component } from 'react'
import Football from './football';
import './App.css';

class App extends Component {
  constructor() {
    super()

    this.state = {
      inputComplete: false,
      weatherData: {},
      input: 0,
      normalSandwich: 0,
      spicySandwich: 0,
      water: 0,
      soda: 0,
      brownie: 0,
      isFridayGame: false
    }

    this.changeInput = this.changeInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sandwichAlgorithm = this.sandwichAlgorithm.bind(this);
    this.getWeatherData = this.getWeatherData.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
  }

  componentDidMount() {
    this.getWeatherData();
  }


  async getWeatherData() {
    fetch('https://api.openweathermap.org/data/2.5/onecall?lat=38.9983&lon=-104.8613&exclude=current,minutely,hourly,alerts&units=imperial&appid=4e586b379c5486bf7141e1d77afb66be')
    .then(data => data.json())
    .then(weatherData => this.setState({weatherData: weatherData}))
    .catch(error => console.log(error))
  }

  changeInput(event) {
    this.setState({input: event.target.value})
  }

  handleSubmit(event) {
    event.preventDefault()
    const sands = this.sandwichAlgorithm()
    this.setState({normalSandwich: sands.cfaSand, spicySandwich: sands.spicySand, water: sands.water, soda: sands.soda, brownie: sands.brownie, inputComplete: true})
  }

  handleCheckChange(event) {
    event.target.value = !event.target.value
    this.setState({isFridayGame: !this.state.isFridayGame})
  }

  sandwichAlgorithm() {
    const gameDay = this.state.weatherData.daily.find(day => {
      const newDate = new Date(day.dt * 1000)
      if (this.state.isFridayGame) {
        return newDate.getDay() === 5
      } else {
        return newDate.getDay() === 6
      }
    })
    let cfaPercent = 0
    let spicyPercent = 0
    let waterPercent = 0
    let sodaPercent = 0
    let cfaHalfsies = 0
    let spicyHalfsies = 0

    const willItRain = gameDay.weather[0].main === "Rain"
    const overThreshold = this.state.input >= 28000

    if (willItRain) {
      console.log("Rain")
      cfaPercent = .02233
      spicyPercent = .01375
      waterPercent = .00533
      sodaPercent = .00675
    } else {
      cfaPercent = .031904
      spicyPercent = .01393
      waterPercent = .0071025
      sodaPercent = .01014
    }

    const cfaSand = this.state.input <= 22000 ? Math.ceil(this.state.input * cfaPercent) + 20 : Math.ceil(this.state.input * cfaPercent)
    const spicySand = Math.ceil(this.state.input * spicyPercent)
    const water = Math.ceil(this.state.input * waterPercent)
    const soda = Math.ceil(this.state.input * sodaPercent)
    if (overThreshold) {
      cfaHalfsies = 60 * (Math.ceil(cfaSand/120))
      spicyHalfsies = 60 * (Math.ceil(spicySand/120))
    } else {
      cfaHalfsies = 60 * (Math.floor(cfaSand/120))
      spicyHalfsies = 60 * (Math.floor(spicySand/120))
    }
    const waterHalfsies = Math.ceil(water/2)
    const sodaHalfsies = Math.ceil(soda/2)
    return {cfaSand: cfaHalfsies, spicySand: spicyHalfsies, water: waterHalfsies, soda: sodaHalfsies, brownie: 45}
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1 className="title">GAME DAY CALC</h1>
          {!this.state.inputComplete && (
            <Football fill="#61DAFB" className="App-logo" alt="logo" />
          )}
          {this.state.inputComplete && (
            <Football fill="green" className="App-logo" alt="logo" />
          )}
          {!this.state.inputComplete && (
            <form onSubmit={this.handleSubmit}>
              <label>
                How many people are attending?
                <input type="number" name="value" value={this.state.input} onChange={this.changeInput}/>
              </label>
              <br/>
              <label>
                Check if it's a Friday game
                <input type='checkbox' checked={this.state.isFridayGame} onChange={this.handleCheckChange}/>
              </label>
              <input type="submit" value="Submit" />
            </form>
          )}
          {this.state.inputComplete && (
            <div className="dataArea">
              <section className="storeArea1">
                <h1>Northgate</h1>
                <h2>{this.state.normalSandwich}</h2>
                <h2>{this.state.spicySandwich}</h2>
                <h2>{this.state.water}</h2>
                <h2>{this.state.soda}</h2>
                <h2>{this.state.brownie}</h2>
              </section>
              <section className="labels">
                <h1>Store</h1>
                <h2>CFA</h2>
                <h2>Spicy</h2>
                <h2>Water</h2>
                <h2>Soda</h2>
                <h2>Brownies</h2>
              </section>
              <section className="storeArea2">
                <h1>Academy</h1>
                <h2>{this.state.normalSandwich}</h2>
                <h2>{this.state.spicySandwich}</h2>
                <h2>{this.state.water}</h2>
                <h2>{this.state.soda}</h2>
                <h2>{this.state.brownie}</h2>
              </section>
            </div>
          )}

        </header>
      </div>
    )
  }
}

export default App;

//https://api.openweathermap.org/data/3.0/onecall?lat=38.9983&lon=104.8613&exclude=current,minutely,hourly,alerts&units=imperial&appid=4e586b379c5486bf7141e1d77afb66be
